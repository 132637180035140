var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Feedback',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Push notifications to your visitors with a "),_c('code',[_vm._v("<b-toast>")]),_vm._v(" and "),_c('code',[_vm._v("<b-toaster>")]),_vm._v(", lightweight components which are easily customizable for generating alert messages. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('on-demand')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variants')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('target')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('links')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('advanced')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }